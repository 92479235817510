import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { WindowSizeInterface, WinEventInterface } from '../interfaces/window-size.interface';

@Injectable({
    providedIn: 'root'
})
export class WindowService implements OnInit {

    constructor() {
        fromEvent<UIEvent>(window, 'resize')
            .pipe(
                distinctUntilChanged(),
                debounceTime(300),
                map(event => {
                    const target = event.target as Window;
                    return <WindowSizeInterface>{
                        bootstrap: WindowService.bootstrap(target.innerWidth),
                        width: target.innerWidth,
                        height: target.outerHeight,
                    };
                })
            ).subscribe((windowSize) => {
                this.windowSizeChanged.next(windowSize);
            });
    }

    readonly windowSizeChanged = new BehaviorSubject<WindowSizeInterface>(<WindowSizeInterface>{
        bootstrap: WindowService.bootstrap(window.innerWidth),
        mobile: WindowService.mobile(window.innerWidth),
        width: window.innerWidth,
        innerHeight: window.innerHeight,
        height: window.outerHeight
    });

    static bootstrap(width): string {

        if (width < 576) {
            return 'w-xs';
        } else if (width < 768) {
            return 'w-sm';
        } else if (width < 992) {
            return 'w-md';
        } else if (width < 1200) {
            return 'w-lg';
        }
        return 'w-xl';
    }

    static mobile(width): boolean {

        return width < 576;
    }

    ngOnInit() {
        this.windowSizeChanged.next(<WindowSizeInterface>{
            bootstrap: WindowService.bootstrap(window.innerWidth),
            width: window.innerWidth,
            height: window.outerHeight,
            innerHeight: window.innerHeight,
        });
    }
}
