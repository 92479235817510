import { environment } from '../../../environments/environment';
import { Base64 } from 'js-base64';
import * as Sentry from '@sentry/angular';

// Função para obter o usuário atual
const getCurrentUser = () => {
    try {
        const userStr = localStorage.getItem('currentUser');
        if (userStr) {
            const user = JSON.parse(userStr);
            return {
                email: Base64.decode(user.email),
            };
        }
        return null;
    } catch {
        return null;
    }
};

// Função para ignorar se um erro é de uma biblioteca de terceiros
const isThirdPartyError = (event: any): boolean => {

    const exception = event.exception?.values?.[0];
    if (exception) {
        const errorMessage = exception.value || '';
        const errorType = exception.type || '';
        if (errorMessage.includes('text/html') ||
            errorMessage.includes('primeng') ||
            errorMessage.includes('@angular') ||
            errorMessage.includes('node_modules') ||
            errorType.includes('primeng')) {
            return true;
        }
    }

    const stacktrace = event.exception?.values?.[0]?.stacktrace;
    if (stacktrace?.frames) {
        return stacktrace.frames.some(
            frame => {
                const filename = frame.filename || '';
                return filename.includes('text/html') ||
                       filename.includes('node_modules') ||
                       filename.includes('service-worker') ||
                       filename.includes('app.js') ||
                       filename.includes('pwa.js') ||
                       filename.includes('primeng') ||
                       filename.includes('primeng-calendar');
            }
        );
    }

    return false;
};

// Função para formatar o tipo de erro
const formatErrorType = (event: any): void => {

    if (event.exception) {
        const error = event.exception.values[0];
        if (error.type === 'TypeError') {
            if (error.value.includes('undefined (reading')) {
                const prop = error.value.match(/reading '(.+)'/)?.[1];
                error.type = `Propriedade Indefinida: ${prop}`;
            } else if (error.value.includes('null (reading')) {
                const prop = error.value.match(/reading '(.+)'/)?.[1];
                error.type = `Propriedade Nula: ${prop}`;
            } else if (error.value.includes('is not a function')) {
                const func = error.value.match(/(.+) is not a function/)?.[1];
                error.type = `Função Inválida: ${func}`;
            } else if (error.value.includes('is not a valid')) {
                error.type = `Tipo Inválido: ${error.value}`;
            }
        }
    }
};

// Função para adicionar informações adicionais ao evento
const addEventInfo = (event: any): void => {

    // Captura informações da requisição XHR se disponível
    const xhr = (event as any).extra?.xhr;
    if (xhr) {
        event.extra = {
            ...event.extra,
            xhr_response: {
                status: xhr.status,
                response: xhr.response,
                headers: xhr.getAllResponseHeaders()
            }
        };
    }

    // Adiciona informações do usuário ao evento
    const user = getCurrentUser();
    if (user) {
        event.user = user;
    }
};

// Função para inicializar o Sentry
export const initSentry = (): void => {
    const localeId = localStorage.getItem('language') ? localStorage.getItem('language').replace('_', '-') : 'pt-BR';

    Sentry.init({
        dsn: environment.SENTRY_DSN,
        enabled: environment.production,
        environment: environment.production ? 'production' : 'development',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                networkDetailAllowUrls: [environment.API_URL],
                networkCaptureBodies: true,
                networkRequestHeaders: ['content-type', 'accept'],
                networkResponseHeaders: ['content-type'],
                maskAllText: false,
                blockAllMedia: false,
            })
        ],
        beforeSend(event) {

            if (isThirdPartyError(event)) {
                return null;
            }

            formatErrorType(event);

            addEventInfo(event);

            return event;
        },
        initialScope: {
            user: getCurrentUser(),
            tags: {
                locale: localeId,
            }
        },
        tracesSampleRate: environment.production ? 0.2 : 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        tracePropagationTargets: ['localhost', environment.API_URL],
        ignoreErrors: [
            /token could not be parsed/,
        ],
    });
};
