import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';

@Injectable({
    providedIn: 'root'
})
export class GeolocationService {

    public latitude: number = null;
    public longitude: number = null;
    public accuracy: number = null;
    public geolocation: string = '0';
    private readonly isWeb: boolean;

    constructor() {
        this.isWeb = !Capacitor.isNativePlatform();
        this.initializeGeolocation();
    }

    private async initializeGeolocation(): Promise<void> {
        try {

            if (this.isWeb && !('geolocation' in navigator)) {
                throw new Error('Geolocalização não suportada no navegador.');
            }

            const permission = await Geolocation.checkPermissions();
            if (permission.location === 'prompt') {
                await Geolocation.requestPermissions();
            }

            await this.updatePosition();

        } catch (error) {
            // console.error('Erro ao inicializar geolocalização:', error);
            if (this.isWeb && 'geolocation' in navigator) {
                this.useNavigatorGeolocation();
            }
        }
    }

    private async updatePosition(): Promise<void> {

        try {
            const position = await Geolocation.getCurrentPosition({
                enableHighAccuracy: true,
                timeout: 10000
            });

            this.updateLocationData(
                position.coords.latitude,
                position.coords.longitude,
                position.coords.accuracy
            );
        } catch (error) {
            // console.error('Erro ao obter a posição:', error);
            if (this.isWeb && 'geolocation' in navigator) {
                this.useNavigatorGeolocation();
            }
        }
    }

    private useNavigatorGeolocation(): void {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                this.updateLocationData(
                    position.coords.latitude,
                    position.coords.longitude,
                    position.coords.accuracy
                );
            },
            (error) => {
                // console.error('Erro ao obter a posição:', error);
            },
            {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0
            }
        );
    }

    private updateLocationData(latitude: number, longitude: number, accuracy: number): void {
        this.latitude = latitude;
        this.longitude = longitude;
        this.accuracy = accuracy;
        this.geolocation = `${latitude};${longitude};${accuracy}`;
    }

    public async refreshLocation(): Promise<void> {
        await this.updatePosition();
    }
}
