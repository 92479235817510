import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { chevronDownCircleOutline } from 'ionicons/icons';

import * as Sentry from '@sentry/angular';
import Lara from '@primeng/themes/lara';
import ptBrLocale from 'primelocale/pt-BR.json';

import { HttpLoaderFactory } from './app/app.module';
import { environment } from './environments/environment';
import { ErrorsInterceptor } from './app/shared/interceptors/errors.interceptor';
import { JwtInterceptor } from './app/shared/interceptors/jwt.interceptor';
import { LoaderInterceptor } from './app/shared/interceptors/loader.interceptor';
import { VersionCheckService } from './app/shared/services/version-check.service';
import { CoreModule } from './app/core/core.module';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { initSentry } from './app/shared/services/sentry.service';

const localeId = localStorage.getItem('language') ? localStorage.getItem('language').replace('_', '-') : 'pt-BR';
const ptBR = ptBrLocale["pt-BR"];

// Inicializa o Sentry
initSentry();

if (environment.production) {
    enableProdMode();
}

addIcons({
    'chevron-down-circle-outline': chevronDownCircleOutline
});

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            CoreModule,
            AppRoutingModule,
            ToastrModule.forRoot(),
            TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: localStorage.getItem('language') ? localStorage.getItem('language') : 'pt_BR'
        })),
        { provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
        { provide: Sentry.TraceService, deps: [Router] },
        { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: localeId },
        VersionCheckService,
        provideIonicAngular({ mode: 'ios' }),
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimationsAsync(),
        providePrimeNG({
            translation: ptBR,
            theme: {
                preset: Lara,
                options: {
                    darkModeSelector: 'none'
                },
            },
        }),
    ]
})
  .catch(err => console.error(err));
