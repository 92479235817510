import { EventEmitter, Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { App } from '@capacitor/app';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PushNotificationService {

    public notificationReceived = new EventEmitter<any>();
    private isNative = Capacitor.isNativePlatform();

    constructor(
        private platform: Platform,
        private router: Router,
        private http: HttpClient
    ) {}

    async initializeWithAppState() {
        try {
            await new Promise(resolve => setTimeout(resolve, 1000));
            const appState = await App.getState();

            if (appState.isActive) {
                await this.initialize();
            }
        } catch (error) {
            console.error('Erro ao inicializar notificações com estado do app:', error);
        }
    }

    async initialize() {
        try {
            if (this.isNative) {
                console.log('Iniciando configuração de notificações');

                let permStatus = await PushNotifications.checkPermissions();

                if (permStatus.receive === 'prompt') {
                    permStatus = await PushNotifications.requestPermissions();
                }

                if (permStatus.receive !== 'granted') {
                    console.warn('Permissão de notificação negada');
                    return;
                }

                try {
                    await PushNotifications.removeAllListeners();
                    await PushNotifications.register();
                    await this.setupNotificationListeners();
                } catch (error) {
                    console.error('Erro durante o registro:', error);
                }

            } else {
                if ('Notification' in window) {
                    const permission = await Notification.requestPermission();
                    if (permission === 'granted') {
                        // console.log('Web notification permission granted');
                    }
                }
            }
        } catch (error) {
            console.error('Erro ao inicializar notificações:', error);
        }
    }

    private async setupNotificationListeners() {
        try {
            // Token received
            await PushNotifications.addListener('registration',
                async (token) => {
                    console.log('Token FCM:', token.value);
                    await this.registerDeviceToken(token.value);
                }
            );

            // Notification received while app is open
            await PushNotifications.addListener('pushNotificationReceived',
                (notification) => {
                    console.log('Notificação recebida:', notification);
                    this.handleForegroundNotification(notification);
                }
            );

            // User clicked on notification
            await PushNotifications.addListener('pushNotificationActionPerformed',
                (notification) => {
                    console.log('Notificação clicada:', notification);
                    this.handleNotificationAction(notification);
                }
            );
        } catch (error) {
            console.error('Erro ao configurar listeners:', error);
        }
    }

    private async registerDeviceToken(token: string) {
        try {
            const deviceId = await Device.getId();
            const deviceInfo = {
                token,
                device_id: deviceId?.identifier,
                platform: Capacitor.getPlatform(),
            };

            await firstValueFrom(
                this.http.post(
                    `${environment.API_URL}users/token_notification`,
                    deviceInfo,
                    { params: { noLoader: '1' } }
                )
            );

            console.log('Dispositivo registrado com sucesso');
        } catch (error) {
            console.error('Erro ao registrar dispositivo:', error);
        }
    }

    private handleForegroundNotification(notification: any) {
        const notificationData = {
            title: notification.title,
            body: notification.body,
            data: notification.data
        };

        this.notificationReceived.emit(notificationData);
    }

    private handleNotificationAction(actionData: any) {
        const notification = actionData.notification;
        const data = notification.data;

        if (data?.action) {
            switch (data.action) {
                case 'openScreen':
                    this.router.navigate([data.screen]);
                    break;
                case 'openUrl':
                    window.open(data.url, '_blank');
                    break;
                default:
                    this.router.navigate(['/notifications']);
            }
        }
    }
}
