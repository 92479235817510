import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Queue, QueuesDBService } from './queues-db.service';
import { environment } from '../../../environments/environment';
import { DebugService } from './debug.service';

@Injectable({
    providedIn: 'root',
})
export class SyncStatusService {

    public syncing = false;

    private apiUrl: string;

    constructor(private queuesDBService: QueuesDBService, private http: HttpClient, private debugService: DebugService) {
        this.apiUrl = environment.API_URL;
        this.sync();
    }

    private sync() {

        if (!navigator.onLine) {
            console.log('Sem conexão com a internet para sincronizar.');
            this.nextResync();
            return;
        }

        this.queuesDBService.getOldestQueue()
            .then((resource) => {

                if (!resource) {
                    this.nextResync();
                    return;
                }

                // Imagens de visitas
                if (resource.reference === 'add-photo' && resource.reference_table === 'visits_files') {
                    this.syncVisitsFiles(resource);
                    return;
                }

                // Imagens de checklists
                if (resource.reference === 'add-photo' && resource.reference_table === 'checklists_answers_files') {
                    this.syncChecklistsAnswersFiles(resource);
                    return;
                }

                // Descrição de imagens
                if (resource.reference === 'update-photo-description') {
                    this.syncFilesDescription(resource, resource.reference_table);
                    return;
                }

                // Deletar imagens
                if (resource.reference === 'delete-photo') {
                    this.syncFilesDelete(resource, resource.reference_table);
                    return;
                }

                console.log('Erro ao sincronizar: Referência não encontrada.', resource);
                this.addError(resource.id, 'Referência não encontrada.');

            })
            .catch((error) => {
                console.error('Erro ao retorna primeiro registro:', error);
                this.nextResync();
            });
    }

    private syncVisitsFiles(resource: Queue) {

        const payload = JSON.parse(resource.payload);
        const body = {
            file: this.base64ToBlob(payload.file),
            file64: payload.file,
            file_caption: payload.file_caption,
            visit_id: resource.reference_id,
            uuid: resource.uuid,
        };

        this.post( resource.id, 'visits_files', body);
    }

    private syncChecklistsAnswersFiles(resource: Queue) {

        const payload = JSON.parse(resource.payload);
        const body = {
            file: this.base64ToBlob(payload.file),
            file64: payload.file,
            file_caption: payload.file_caption,
            checklist_id: payload.checklist_id,
            checklists_answer_id: resource.reference_id,
            uuid: resource.uuid,
        };

        this.post( resource.id, 'checklists_answers_files', body);
    }

    private syncFilesDescription(resource: Queue, table: string) {

        const payload = JSON.parse(resource.payload);
        const body = {
            id: payload.id,
            uuid: payload.uuid,
            file_caption: payload.file_caption,
        };

        this.put( resource.id, table, body );
    }

    private syncFilesDelete(resource: Queue, table: string) {

        const payload = JSON.parse(resource.payload);
        const body = {
            id: payload.id,
            uuid: payload.uuid,
            file_caption: payload.file_caption,
        };

        this.delete( resource.id, table, body );
    }

    private post(id: number, path: string, body: any, withFile = true) {

        this.setSyncing(true);
        let data = body;
        const params = new HttpParams().set('noLoader', 'true');

        if (withFile) {
            data = new FormData();
            for (const key in body) {
                if (body.hasOwnProperty(key)) {
                    data.append(key, body[key]);
                }
            }
        }

        return this.http.post(this.apiUrl + path, data, {params})
            .subscribe((response) => {
                this.queuesDBService.delete(id).then(() => {
                    this.sync();
                });
            }, (error) => {
                console.error('Erro ao sincronizar via post:', error);
                this.addError(id, error);
                this.debugService.sendDebugData({
                    subject: 'Erro ao sincronizar via post (SyncStatusService.post - sync-status.service.ts)',
                    url: this.apiUrl + path,
                    id: id,
                    body: body,
                    withFile: withFile,
                    data: data,
                    error: error,
                });
            });
    }

    private put(id: number, path: string, body: any) {

        this.setSyncing(true);
        const params = new HttpParams().set('noLoader', 'true');

        return this.http.put(this.apiUrl + path + '/' + body.id, body, {params})
            .subscribe((response) => {
                this.queuesDBService.delete(id).then(() => {
                    this.sync();
                });
            }, (error) => {
                console.error('Erro ao sincronizar via put:', error);
                this.addError(id, error);
            });
    }

    private delete(id: number, path: string, body: any) {

        this.setSyncing(true);
        const params = new HttpParams().set('noLoader', 'true')
            .set('uuid', body.uuid);

        return this.http.delete(this.apiUrl + path + '/' + body.id, {params})
            .subscribe((response) => {
                this.queuesDBService.delete(id).then(() => {
                    this.sync();
                });
            }, (error) => {
                console.error('Erro ao sincronizar via delete:', error);
                this.addError(id, error);
            });
    }

    private nextResync() {
        this.setSyncing(false);
        setTimeout(() => {
            this.sync();
        }, 5000);
    }

    private setSyncing(value: boolean) {
        this.syncing = value;
        const badgeSyncing = document.querySelector('.badge-syncing');
        const navItemSync = document.querySelector('.nav-item-sync');

        if (!badgeSyncing || !navItemSync) {
            // console.log('Elementos de sincronização não encontrados no DOM.');
            return;
        }

        if (value) {
            badgeSyncing.innerHTML = '<i class="fa fa-refresh fa-spin"></i>';
            navItemSync.setAttribute('style', 'display: list-item;');
        } else {
            badgeSyncing.innerHTML = '';
            navItemSync.setAttribute('style', 'display: none;');
        }
    }

    private addError(id: number, error: any) {
        this.queuesDBService.get(id).then((queue) => {
            if (queue) {
                if (queue.attempts) {
                    queue.attempts++;
                } else {
                    queue.attempts = 1;
                }
                queue.attempt_at = new Date();
                queue.attempt_error = error;
                if (queue.attempts > 5) {
                    this.queuesDBService.addFailed(queue).then(() => {
                        this.queuesDBService.delete(id).then();
                    });
                } else {
                    this.queuesDBService.put(queue).then();
                }
            }
        });
        setTimeout(() => this.nextResync(), 5000);
    }

    private base64ToBlob(base64Data: string): Blob {
        const byteCharacters = atob(base64Data.split(',')[1]);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: 'image/jpeg' });
    }
}
